/* === HEADER === */
header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
  
    // background-color: #3b2f63;
    color: rgb(199, 184, 216);
    color:rgba(121, 74, 162, 0);
    text-transform:uppercase;
    text-decoration:none;
    a {
        text-decoration:none;
    }
    box-shadow: 0px 15px 10px -15px white;
    box-shadow: -4px -3px 3px 5px rgba(206, 205, 205, 0.35);
    margin-bottom:25px;
  }
  
  
  .header-wrapper__nav_list li {
    display: inline-block;
    margin: 0 10px;
  }
  
  /* === TITLES === */
  
  .page-title {
    /* margin: 25px auto; */
    padding: 10px;
    /* border-radius: 2px; */
    text-transform: uppercase;
    background: #f0f0f0;
    /* background: #00e091; */
    font-weight: 600;
    text-align: center;
    font-family: var(--font-family-heading);
    color: white;
    color: black;
  }
  