/* ICONS */
.icons > a {
  display: inline-block;
  width: 50%;
}

.fa-trash-alt,
.fa-edit {
  font-size: 28px;
  color: var(--color-primary);
  cursor: pointer;
}

.fa-edit:hover {
  color: rgb(113, 187, 247);
}

.fa-trash-alt:hover {
  color: rgb(236, 112, 99);
}

.icons {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}
.fa-check-circle {
  color: #2acfcf;
  font-size: 22px;
}

/* icons style */

.fa-github-square {
  font-size: 32px;
  margin: 30px;
  color: #333;
}
.fa-linkedin {
  color: #0077b5;
  font-size: 32px;
  margin: 30px;
}
.fa-twitter-square {
  color: #1da1f2;
  font-size: 32px;
  margin: 30px;
}
.arrow-icon-wrapper {
  display: flex;
  justify-content: flex-end;
}
.fa-chevron-circle-up {
  color: rgb(235, 70, 70);
  color:  #6a0fe9;
  color:white;
  cursor: pointer;
  font-size: 32px;
}
.fa-quote-left, .fa-quote-right {
  color:#b9b5b5;
  font-size:16px;

}
.fa-youtube-square {
    color: #FF0000;
  font-size: 32px;
  margin: 30px;
}
.fa-certificate {
  position: absolute;
  color:rgba(106, 15, 233, 0.65);
  color:var(--color-primary);
  font-size: 20px;
  top:65%;
  right:45%;
  z-index: 10;
}
.fa-spin {
  color:var(--color-primary);
  font-size:50px;
}
.gender-icon  {
  color:var(--color-primary);
}
.fa-chevron-left, .fa-chevron-right{
  color:var(--color-primary);
  font-size:50px;
  opacity: 0.15;
  cursor: pointer;

}
.fa-chevron-left:hover, .fa-chevron-right:hover {
  opacity: 0.75;
}
.fa-chart-bar  {
  color:var(--color-primary);
  font-size:50px;
  cursor: pointer;
}