/*=== FORM  === */
form {
  height: 100%;
  margin: 30px auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.row > div {
  width: 25%;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
}

input,
textarea,
select {
  padding: 15px;
  margin: 15px 0;
  min-width:250px;
  outline: none;
  font-size: 16px;
  border: 1px solid rgba(234, 222, 245, 0.78);
  padding: 15px 20px;
  border-radius: 10px;
  line-height: inherit;
  color: #4e5255;
}

input + p {
  display: none;
}

input:focus,
select:focus,
textarea:focus,
input:hover,
select:hover,
textarea:hover,
select:focus,
[for|='image']:hover,
[for|='image']:focus {
  background: #eadef5;
}

textarea {
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.file-upload {
  width: 25%;
}
inpt[type='file'] {
  width: 10%;
}

[for|='image'] {
  background: #eadef5;
  padding: 15px;
  border-radius: 25px;
}
label {
  font-size:18px;
  font-weight: 300;
}
::placeholder, option {
  color:rgb(0, 0, 0, 0.4);
  
}


@media (max-width: 768px) {
  form {
    width: 95%;
    padding: 0;
    margin: 0;
    margin: auto;
  }
  input,
  textarea {
    width: 100%;
  }
}
