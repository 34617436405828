/* === GENERAL === */

  *, *::after, **::before {
    margin: 0;
    padding:0;
    box-sizing: border-box;
  }

:root {
  --font-family-heading: 'Oswald', serif;
  --font-family-heading: 'Roboto', serif;
  --font-family-body: 'Cardo', sans-serif;
  --font-family-body: 'Roboto', sans-serif;
  --color-primary: #3b2f63;
  --color-primary-lighter: #794aa2;
  --color-text: #525c65;
  --background-body: rgba(226, 214, 214, 0.1);

  --font-size-regular-text: 18px;
  --font-size-heading: 26px;
}

html,
body {
  height: 100%;
  /* background: var(--background-body); */
  line-height: 1.7;
  font-family: var(--font-family-body);
  /* background: #f0f1f7; */
  font-size: 18px;
  overflow-x: hidden;


    line-height: 1.75;
    font-family: Roboto;
  /*   font-family: Inconsolata;
    font-family: Raleway; */
  
}

.header-wrapper,
.main-wrapper,
.footer-wrapper {
  margin: auto;
  width: 95%;
}

/* === app wrapper === */
.wrapper {
  min-height: 100%;
  position: relative;
  letter-spacing: 1.25px;
}

/* === MAIN === */
main {
  width:75%;
  margin:auto;
  padding-bottom: 250px;
  font-size: 130%;
}

h1,
h2,
h3,
/* h4, */
h5,
h6 {
  font-weight: 300;
  font-size: var(--font-size-heading);
  /* font-family: 'Popins'; */
  /* text-transform: uppercase; */
}

p {
  /* font-family: var(--font-family-body); */
  font-weight: 300;
}

/* a {
  text-decoration: none;
  color: black;
} */

/* ul li a {
  color: white;
  font-size: 18px;
  text-transform: uppercase;
} */


small {
  font-size: 18px !important;
  font-family: 'Roboto' !important;
}

@media (max-width: 768px){
  main {
    width: 95%;
  }
}