/* === FOOTER === */
footer {
  display: flex;
   position: absolute; 
  bottom: 0;
  width: 100%;
  height: 250px;
  background: #f1ebf1;
  background:rgba(121, 74, 162, 1);
  align-items: center;
  text-align: center;
  color:white;
  
  
}

small {
  font-weight: 16px;
  font-family: Montserrat;
  font-weight: 300;
  padding-bottom: 10px;
}


  .copyright {
  color:white;
  font-weight: 600;
  }

.footer-wrapper > div a {
  margin:25px;
}