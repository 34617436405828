.card-image {
  display: block;
  max-width: 15%;
  min-width: 15%;
  /* border: 1px solid var(--color-primary); */
}
.profile-image {
  display: block;
  /* width: 10%;
  width: 10%; */
  border-radius: 50%;
}

.profile-category {
  margin: 25px auto;
}
.profile-category > h4 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.profile-socials {
  margin: auto;
  display: flex;
}
.profile-socials a {
  margin-right: 10px;
}
.profile-settings {
}

.single-student {
  background: white;
  margin: 0 0 50px 0;
}
.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: #eadef5;
}
.profile-body {
  padding: 30px;
}
.profile-footer {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.student {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: background-color 0.5s ease;
  background: #fff;
  padding: 1.6rem;
  border-radius: 0.4rem;
  box-shadow: 0 0 0 0.1px #bfbfbf;
  margin: 25px auto;
  font-size: 120%;
}

.student:hover {
  background: rgb(252, 251, 255);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.bio {
  padding: 25px 50px;
  margin: 10px 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: 'Roboto' !important;
  letter-spacing: 0.0625em !important;
  background: rgb(238, 236, 236);
  color: #111;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 0.1px #bfbfbf;
  box-shadow: 0 0 0 0.1px #bfbfbf;
  line-height: 170%;
  font-size: 1rem !important;
  font-weight: 300 !important;
  font-style: normal !important;
}

.user-avatar {
  display: inline-block;
  /* width: 35px; */
  /* height: 35px; */
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  background: rgb(106, 109, 106);
}
small {
  font-weight: 300;
  font-family: 'Lora';
}
span.key {
  border: 1px solid var(--color-primary-lighter);
  border-radius: 20px;
  margin-right: 10px;
  padding: 5px 20px;
}
hr {
  border: 3px solid #eadef5;
  border: 1px solid #2acfcf;
  margin: 10px auto;
}
.skills {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin: 25px 0px;
  font-size: 20px;
}
.skills > div {
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin: 8px;
}

.skill {
  width: 100%;
  display: inline-block;
  border-radius: 10px;
  background: #f0f1f7;
  color: rgb(54, 52, 52);
  font-size: 22px;
  padding: 5px 10px;
  background: #2acfcf;
  color: white;
}
.student-status {
  min-width: 45%;
  display: flex;

  justify-content: space-between;
}
.student-status > button,
.student-status > small {
}
.contacts {
  display: flex;
  justify-content: center;
  justify-items: center;
}
.contacts > small {
  margin: 10px;
  font-weight: 100;
  font-size-adjust: 12px;
}

/* === RESPONSIVE === */
@media (max-width: 768px) {
  body {
    font-size: 1rem;
  }
  .header-wrapper {
    margin: 25px 0 0 0;
  }

  .header-wrapper,
  .main-wrapper,
  .footer-wrapper {
    margin: auto;
    width: 100%;
  }

  .header-wrapper__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }

  .header-wrapper__nav ul {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-wrapper__nav ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    width: 100%;
    padding: 10px 50px;
  }

  img {
    display: block;
    height: auto;
    text-align: center;
  }

  article {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .profile-header,
  .profile-body {
    padding: 25px;
  }

  .row {
    display: flex;
    flex-direction: column;
  }
  .row > div {
    width: 100%;
  }
  .bio {
    padding: 15px;
    margin: 3px auto;
  }

  .student-status {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .student-status > button,
  .student-status > small {
    width: 100%;
    padding: 0;
    padding: 15px;
    text-align: center;
  }

  .single-student {
    margin: 0 0 25px 0;
  }
  .profile-header small {
    font-size: 0.5em;
  }
  .profile-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin: 10px;
  }
  .contacts > small {
    margin: 0;
    padding: 0;
  }

  .card-image {
    display: block;
    min-width: 40%;
  }
  .header-wrapper,
  .main-wrapper,
  .footer-wrapper {
    padding: 0 5px;
    font-size: 1rem;
  }
}
