.hero {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    h1 {
        font-size: 48px;
        text-align: center;
        font-weight: 900;
        text-transform: uppercase;
     
    }
    h1 span {
        color:#A24FC7;

    }

    .hero-flex {
        min-height: 75vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .hero-flex  div {
        flex:1;
        margin:10px;
    }
    .hero-right  {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .hero-right img {
        max-width: 75%;
    }
    
}
.web-techs {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 2rem;
      color:#A24FC7;
      text-align: center;
      
    }
    .web_techs__title {
      text-align: center;
      font-weight: 300;
    }
    
  }
  
  .web-tech__images {
    display: flex;
    margin: 120px auto;
    justify-content: space-around;
  }
  
  .web-tech__images img {
    display: block;
    max-width: 10%;
    min-width: 10%;
    height: auto;
    margin: 20px auto;
  }
  

@media (max-width: 768px) { 
    .hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
            font-size: 28px;
            text-align: center;
            font-weight: 900;
            text-transform: uppercase;
         
        }
        h1 span {
            color:#A24FC7;
    
        }
        .hero-flex {
            min-height: calc(100vh - 150px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        .hero-flex  div {
            flex:1;
            margin:10px;
        }
        .hero-right  {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .hero-right img {
            max-width: 50%;
        }
       
    }
    .web-techs h2 {
        font-size:32px
    }
    .web-tech__images {
        margin: 30px auto;
      }
      
      .web-tech__images img {
        display: block;
        max-width: 25%;
        min-width: 25%;
      }
    .web-techs {
        h2 {
          font-size: 1.5rem;
          
        }

        
      }

}