
.postCompleted {
  // border-right: 5px solid rgb(35, 175, 35);
}

.postStat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 150px;
  color:#333;
  font-size: 14px;
  i {
    margin-right: 3px;
  }
}