.program {
      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      details {
        margin:25px auto;
        summary {
          background-color: azure;
          background-color:rgba(162, 79, 199, 0.09);
          padding: 25px 15px;
          font-size:20px;
          font-weight: 900;
          color:#A24FC7;
          text-transform: uppercase;
        }
      }
      h1, h2, h3, h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.25rem;
        color:#A24FC7;
        margin: 30px 0;
      }
      ul.techs  {
        display: flex;
        flex-wrap: wrap;
        margin: 15px 0px;
      
      }
      ul.techs  li {
        list-style: none;
        margin: 10px;
        min-width: 150px;
        padding: 10px;
        text-align: center;
        background-color: rgb(83, 29, 133);
        color:white;
        font-weight: bold;
        border-radius: 25px;
        text-transform: uppercase;
      }
      
      .program > ul li {
        list-style: none;
        background-color: rgba(243, 241, 237, 0.65);
        margin: 10px auto;
        padding: 10px;
        border-radius: 3px;
      }
      
      @media only screen and (max-width: 600px) {
        body {
          line-height: 1.75;
        }
        #root {
          max-width:95%;
        }
      
        ul.techs  li {
          margin: 5px;
          padding: 5px;
          font-size: 0.75rem;
        }
        
        .program > ul li {
          margin: 5px auto;
          padding: 5px;
        }
        h1, h2, h3, h4 {
          font-size: 0.85rem;
          margin: 10px 0;
        }
      
      }
      details {
        margin:15px auto;
        summary {
          padding: 15px 5px;
          font-size:18px;
          font-weight: 900;
        }
      }
}