/*=== LINKS ===*/
.link {
  text-decoration: none;
  border-bottom: 1px solid #3b2f63;
  padding: 5px 10px;
  color: rgb(85, 85, 85);
}

.link:hover {
  background: #3b2f63;
  color: white;
}

.ul-list {
  position: relative;
  z-index: 10;
}

.list {
  margin-left: 35px;
  font-weight: 300;
  list-style: none;
  line-height: 40px;
}

.list::before {
  position: absolute;
  left: -10px;
  content: '';
  display: inline-block;
  width: 35px;
  height: 35px;
  background-image: url(../images/devosome_logos/devosome_shot_logo_transparent.png);
  background-size: cover;
  
}
